/* eslint-disable*/
import React, { useEffect, useState } from "react";
import axiosInstance from "../api/api";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// const reactCookie = require("react-cookies");
import toast, { Toaster } from "react-hot-toast";
import { Box, Grid, Typography } from "@mui/material";
import useScreenSize from "../hooks/useScreenSize";
import { Button, Form, Input, Space, Table, Modal } from "antd";
import { create } from "@mui/material/styles/createTransitions";
const { Column, ColumnGroup } = Table;

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
const emptyData = [
  {
    _id: "",
    cert_id: "",
    domains_name: "",
  },
];

function Createsllcert() {
  var options = [];
  const [form] = Form.useForm();
  const [certdata, setCertdata] = useState(emptyData);
  const [modalDelete, setModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteButton, setDeleteButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const getSllcert = async () => {
    try {
      const res = await axiosInstance.get(`api/ssl-cert/read/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (res.status === 200) {
        options = [];
        res.data.data.map((item, index) => {
          options.push({
            key: index,
            certid: item._id,
            domain_name: item.domains_name,
          });
        });
        setCertdata(options);
        return res.data;
      }
      // setSllcert(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const openDeleteModel = (e) => {
    setModalDelete(true);
    console.log("Delete", e.target.id);
    setDeleteId(e.target.id);
    // deleteCert({ certid: e.target.id });
  };

  const createCert = (values) => {
    console.log("Success:", values);
    setLoading(true);
    axiosInstance
      .post(
        `api/ssl-cert/create`,
        {
          domain_name: values.domain,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          setLoading(false);
          form.resetFields();
          toast.success("SSL Certificate created successfully");
          getSllcert();
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  const deleteCert = (e) => {
    console.log("Success:", e.target.id);
    axiosInstance
      .delete(`api/ssl-cert/delete/${e.target.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          getSllcert();
          toast.success("SSL Certificate deleted successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error deleting SSL Certificate");
      });
  };

  // const checkDomainName = (e) => {
  //   console.log("Domain Name", e.target.value);
  //   if (e.target.value === deleteId) {
  //     setDeleteButton(false);
  //   }
  // };

  useEffect(() => {
    getSllcert();
  }, []);
  const [isMobileView, isTabletView] = useScreenSize();
  return (
    <>
      <Toaster position="bottom-right" reverseOrder={false} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: isMobileView ? "20px !important" : "40px !important",
          background: "#fff",
          gap: "30px",
          borderRadius: "8px",
          flex: 1,
          margin: isMobileView ? "25px 10px" : "10px 10px",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
          User Content
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={createCert}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Domain Name :"
              name="domain"
              rules={[
                {
                  required: true,
                  message: "Please input your domain name!",
                },
              ]}
            >
              <Input placeholder="Domain name" />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Table
            dataSource={certdata}
            scroll={{
              x: 500,
            }}
          >
            <Column
              title="Cert Id"
              dataIndex="certid"
              key="certid"
              render={(_, record) => (
                <Space size="middle">
                  <Link to={`/cert-details/${record.certid}`}>
                    {record.certid}
                  </Link>
                </Space>
              )}
            />
            <Column
              title="Domain Name"
              dataIndex="domain_name"
              key="domain_name"
            />

            <Column
              title="Action"
              key="action"
              render={(_, record) => (
                <Space size="middle">
                  <a style={{color:'#FF4D4E'}} id={record.certid} onClick={(e) => deleteCert(e)}> Delete
                     </a>
                </Space>
              )}
            />
          </Table>
        </Grid>
        <Modal
          title="Delete SSL Certificate"
          centered
          open={modalDelete}
          onOk={() => setModalDelete(false)}
          okText="Delete"
          okType="danger"
          okButtonProps={{ type: "primary" , danger: true,disabled:{deleteButton} }}
          onCancel={() => setModalDelete(false)}
        >
          Write the domain name to delete the SSL Certificate
          <Form.Item
              
              name="domain"
              rules={[
                {
                  required: true,
                  message: "Please input your domain name!",
                },
              ]}
            >
              {/* <Input onChange={checkDomainName} placeholder={deleteId}/> */}
            </Form.Item>
        </Modal>
      </Box>
    </>
  );
}

export default Createsllcert;
