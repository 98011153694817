import React from "react";

const Nutrition = (props) => {
    
    return (
        <>
               <section class="performance-facts">
                            <header class="performance-facts__header">
                                <h1 class="performance-facts__title">Nutrition Facts</h1>
                                <p>Serving Size 1/2 cup (about {props.props.serving_size}g)</p>
                                <p>Serving Per Container {props.props.serving_per_container }</p>
                            </header>
                            <table class="performance-facts__table">
                                <thead>
                                    <tr>
                                        <th colspan="3" class="small-info">
                                            Amount Per Serving
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th colspan="2">
                                            <b>Calories</b>
                                            {props.props.calories}
                                        </th>
                                        <td>
                                            Calories from Fat {props.props.calories_from_fat}
                                        </td>
                                    </tr>
                                    <tr class="thick-row">
                                        <td colspan="3" class="small-info">
                                            <b>% Daily Value*</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colspan="2">
                                            <b>Total Fat</b>
                                            {props.props.total_fat} g
                                        </th>
                                        <td>
                                            <b>{props.props.total_fat_percentage}%</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="blank-cell">
                                        </td>
                                        <th>
                                            Saturated Fat {props.props.saturated}g
                                        </th>
                                        <td>
                                            <b>22%</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="blank-cell">
                                        </td>
                                        <th>
                                            Trans Fat {props.props.trans_fat}g
                                        </th>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colspan="2">
                                            <b>Cholesterol</b>
                                            55mg
                                        </th>
                                        <td>
                                            <b>18%</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colspan="2">
                                            <b>Sodium</b>
                                            40mg
                                        </th>
                                        <td>
                                            <b>2%</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colspan="2">
                                            <b>Total Carbohydrate</b>
                                            17g
                                        </th>
                                        <td>
                                            <b>6%</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="blank-cell">
                                        </td>
                                        <th>
                                            Dietary Fiber
                                            1g
                                        </th>
                                        <td>
                                            <b>4%</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="blank-cell">
                                        </td>
                                        <th>
                                            Sugars {props.props.sugars}g
                                        </th>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr class="thick-end">
                                        <th colspan="2">
                                            <b>Protein</b> {props.props.protein}g
                                        </th>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="performance-facts__table--grid">
                                <tbody>
                                    <tr>
                                        <td colspan="2">
                                            Vitamin A
                                            10%
                                        </td>
                                        <td>
                                            Vitamin C
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="thin-end">
                                        <td colspan="2">
                                            Calcium
                                            10%
                                        </td>
                                        <td>
                                            Iron
                                            6%
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <p class="small-info">* Percent Daily Values are based on a 2,000 calorie diet. Your daily values may be higher or lower depending on your calorie needs:</p>

                            <table class="performance-facts__table--small small-info">
                                <thead>
                                    <tr>
                                        <td colspan="2"></td>
                                        <th>Calories:</th>
                                        <th>2,000</th>
                                        <th>2,500</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th colspan="2">Total Fat</th>
                                        <td>Less than</td>
                                        <td>65g</td>
                                        <td>80g</td>
                                    </tr>
                                    <tr>
                                        <td class="blank-cell"></td>
                                        <th>Saturated Fat</th>
                                        <td>Less than</td>
                                        <td>20g</td>
                                        <td>25g</td>
                                    </tr>
                                    <tr>
                                        <th colspan="2">Cholesterol</th>
                                        <td>Less than</td>
                                        <td>300mg</td>
                                        <td>300 mg</td>
                                    </tr>
                                    <tr>
                                        <th colspan="2">Sodium</th>
                                        <td>Less than</td>
                                        <td>2,400mg</td>
                                        <td>2,400mg</td>
                                    </tr>
                                    <tr>
                                        <th colspan="3">Total Carbohydrate</th>
                                        <td>300g</td>
                                        <td>375g</td>
                                    </tr>
                                    <tr>
                                        <td class="blank-cell"></td>
                                        <th colspan="2">Dietary Fiber</th>
                                        <td>25g</td>
                                        <td>30g</td>
                                    </tr>
                                </tbody>
                            </table>

                            <p class="small-info">
                                Calories per gram:
                            </p>
                            <p class="small-info text-center">
                                Fat 9
                                &bull;
                                Carbohydrate 4
                                &bull;
                                Protein 4
                            </p>

                        </section>
        </>
    );
    }

export default Nutrition;