import { Outlet } from "react-router-dom";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Header from "../templates/Header";
import Footer from "../templates/Footer";
import React, { useState } from 'react';
import { UploadOutlined, UserOutlined, VideoCameraOutlined, ProfileOutlined } from '@ant-design/icons';
import { Layout, Menu, theme, Breadcrumb } from 'antd';
import { FloatButton } from 'antd';
import { Nav } from "react-bootstrap";
import { Toaster } from 'react-hot-toast';
const { Content, Sider } = Layout;


export default function Layout1() {
  const navigate = useNavigate();
  const items = [
    {
      key: '1',
      label: 'Certificate',
      icon: <UserOutlined />,
      onClick: () => { navigate('/ssl-cert') },
    },
    {
      key: '2',
      label: 'Labels',
      icon: <ProfileOutlined />,
      onClick: () => { navigate('/label') },
    },
    {
      key: '3',
      label: 'Qrcodes',
      icon: <UploadOutlined />,
      onClick: () => { navigate('/qrcode') },
    },
    {
      key: '4',
      label: 'nav 4',
      icon: <UserOutlined />,
      onClick: () => console.log('nav 4'),
    },
    {
      key: '5',
      label: 'Profile',
      icon: <UserOutlined />,
      onClick: () => { navigate('/profile') },
    }
  ];

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  return (
    <main>
      <Toaster position="bottom-right" reverseOrder={false} />

      <FloatButton.BackTop duration={100} visibilityHeight={0} />
      <Header />
      <Layout>
        <Sider
          breakpoint="lg"
          // collapsedWidth="0"         //-> uncomment to hide the sidebar totally
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="demo-logo-vertical" />
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} items={items} />
        </Sider>
        <Layout>

          <Content
            style={{
              margin: '24px 16px 24px',
            }}
          >
            <Breadcrumb style={{ marginBottom: "10px" }} >
              <Breadcrumb.Item>User</Breadcrumb.Item>
              <Breadcrumb.Item>Bill</Breadcrumb.Item>
            </Breadcrumb>
            <div
              style={{
                padding: 24,
                minHeight: '100vh',
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Outlet />
            </div>
          </Content>

          <Footer />
        </Layout>
      </Layout>


    </main>
  )
}

