import React from "react";
import logo from "../assets/digilabel.svg";
import json from "../../package.json";
import { Col, Container, Image, Nav, Row, Stack } from "react-bootstrap";
const Footer = () => {
  const version = json.version;
  const description = json.description;
  const [serverVersion, setServerVersion] = React.useState("");

  return (
    <>
      <footer>
        <Container fluid>
          <Row className="p-4 px-xl-5 bg-primary">
            <Col className="mx-5">
              <Stack>
                <Image src={logo} alt="digilabel" style={{ width: "100px" }} />
              </Stack>
            </Col>
            <Col className="mx-5">
              <Nav className="justify-content-end">
                <Nav.Link href="/about" className="text-white">
                  About
                </Nav.Link>
                <Nav.Link href="/contact" className="text-white">
                  Contact
                </Nav.Link>
                <Nav.Link href="/privacy" className="text-white">
                  Privacy
                </Nav.Link>
              </Nav>
            </Col>
            <Col className="mx-5">
              <Nav className="justify-content-end">
                <Nav.Link href="/terms" className="text-white">
                  Terms
                </Nav.Link>
                <Nav.Link href="/faq" className="text-white">
                  FAQ
                </Nav.Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
export default Footer;
