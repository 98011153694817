import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../api/api";
import { Box, Grid, Typography } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import {Button,Table,Popover,Space,} from "antd";
import useScreenSize from "../hooks/useScreenSize";
import { IoCopyOutline } from "react-icons/io5";

const { Column } = Table;

const Labels = () => {
  const [isMobileView] = useScreenSize();
  const [labelData, setLabelData] = useState([]);

  const [openUrl, setOpenUrl] = useState(false);
  const hide = () => {
    setOpenUrl(false);
  };
  const handleOpenChange = (newOpen) => setOpenUrl(newOpen);
  const navigate = useNavigate();
  return (
    <>
      <Toaster position="bottom-right" reverseOrder={false} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: isMobileView ? "20px !important" : "40px !important",
          background: "#fff",
          gap: "30px",
          borderRadius: "8px",
          flex: 1,
          margin: isMobileView ? "25px 10px" : "10px 10px",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
          Labels
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Table
            scroll={{
              x: 1500,
            }}
            style={{ width: "100%" ,
              margin: "10px 10px",
              
            }}
            bordered
            dataSource={labelData}
          >
            <Column width={200} title="Qr Id" dataIndex="qr_id" key="qr_id" />
            <Column width={200} title="Qr Name" dataIndex="name" key="name" />
            <Column width={200} title="Status" dataIndex="status" key="status" />
            <Column
              title="Url"
              dataIndex="url"
              key="url"
              render={(_, record) =>
                renderCopyIcon(
                  record.url,
                  hide,
                  openUrl,
                  handleOpenChange
                )
              }
            />
            <Column
              title="Action"
              key="action"
              render={(_, record) => (
                <Space size="middle">
                  <a>Delete</a>
                </Space>
              )}
            />
          </Table>
          
          <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
          <Button
            style={{ position: "middle", maxWidth: "200px" }}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {navigate("/create-label")}}
          >
            {" "}
            Add Label
          </Button>
        </Typography>
        </Grid>
      </Box>
    </>
  );
};

const renderCopyIcon = (text, hide, open, handleOpenChange) => {
  return (
    <Space size="middle">
      {text}
      <Popover
        content={<a onClick={hide}>Close</a>}
        title="Copied"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <IoCopyOutline
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigator.clipboard.writeText(text);
          }}
        />
      </Popover>
    </Space>
  );
};

export default Labels;
