import React from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { CgProfile } from "react-icons/cg";
import { useNavigate, Link } from "react-router-dom";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import logo from "../assets/digilabel.svg";
const { Header, Content, Sider } = Layout;

const Navbar = () => {
  const navigate = useNavigate();
  const items1 = [
    {
      key: "1",
      icon: <CgProfile style={{ justifyContent: "center" }} size={30} />,
      onClick: () => navigate("/profile"),
      style: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
    },
    {
      key: "2",
      label: "nav 2",
      icon: React.createElement(LaptopOutlined),
      onClick: () => console.log("nav 2"),
    },
  ];
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        width: "100%",
        display: "flex",
        alignItems: "center",
        WebkitBackfaceVisibility: "hidden",
      }}
    >
      <div className="demo-logo">
        <Link to={`/`}>
          <img src={logo} style={{ maxHeight: "40px" }} Link={"/"} />
        </Link>
      </div>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[""]}
        items={items1}
        style={{
          flex: 1,
          minWidth: 0,
          float: "right",
          position: "absolute",
          right: 0,
        }}
      />
    </Header>
  );
};
export default Navbar;
