import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../api/api";
import { Box, Grid, Typography } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Row,
  Button,
  Form,
  Input,
  Radio,
  Switch,
  Upload,
} from "antd";
import useScreenSize from "../hooks/useScreenSize";
import Nutrition from "../templates/Nutrition";
const { TextArea } = Input;
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const Createlabel = () => {
  const [isMobileView] = useScreenSize();
  const [NutritionFacts, setNutritionFacts] = useState({
    serving_size: 0,
    calories: 0,
    total_fat: 0,
    saturated: 0,
  });

  const onFinish = (values) => {
    console.log('Success:', values);
    axiosInstance.post("/api/label/create",NutritionFacts)
    .then(response => {
      console.log(response);
      Toast.success("Nutrition Added");
    })
    .catch(err => {
      console.log(err);
      Toast.error("Error Adding Nutrition");
    })
  };


  return (
    <>
      <Toaster position="bottom-right" reverseOrder={false} />
      <Row>
        <Col span={17}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: isMobileView ? "20px !important" : "40px !important",
              background: "#fff",
              borderRadius: "8px",
              flex: 1,
              margin: isMobileView ? "25px 10px" : "10px 10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Row>
              <Col flex={10}>
                <Form
                    onFinish={onFinish}
                    name="basic"
                >
                  <Form.Item  name='serving_size' 
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]} label="serving size">
                    <Input
                      onChange={(e) =>
                        setNutritionFacts({
                          ...NutritionFacts,
                          serving_size: e.target.value,
                        })
                      }
                      
                      type="number"
                    />
                  </Form.Item>
                  <Form.Item rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]} name='serving_per_container' label="serving per container">
                    <Input
                      onChange={(e) =>
                        setNutritionFacts({
                          ...NutritionFacts,
                          serving_per_container: e.target.value,
                        })
                      }
                    />
                  </Form.Item>

                  <Row>
                    <Col>
             
                      <Form.Item rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]} name='total_carbohydrate' label="Total Carbohydrate">
                        <Input
                          onChange={(e) =>
                            setNutritionFacts({
                              ...NutritionFacts,
                              total_carbohydrate: e.target.value,
                            })
                          }
                          type="number"
                        />
                      </Form.Item>
                      <Form.Item rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]} name='dietary_fiber' label="Dietary Fiber">
                        <Input
                          onChange={(e) =>
                            setNutritionFacts({
                              ...NutritionFacts,
                              dietary_fiber: e.target.value,
                            })
                          }
                          type="number"
                        />
                      </Form.Item>
                      <Form.Item rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]} name='sugars' label="Sugars">
                        <Input
                          onChange={(e) =>
                            setNutritionFacts({
                              ...NutritionFacts,
                              sugars: e.target.value,
                            })
                          }
                          type="number"
                        />
                      </Form.Item>
                      <Form.Item rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]} name='protein'  label="Protein">
                        <Input
                          onChange={(e) =>
                            setNutritionFacts({
                              ...NutritionFacts,
                              protein: e.target.value,
                            })
                          }
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{marginLeft:'10px'}}>
                      <Form.Item rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]} name='calories' label="Calories">
                        <Input
                          onChange={(e) =>
                            setNutritionFacts({
                              ...NutritionFacts,
                              calories: e.target.value,
                            })
                          }
                          type="number"
                        />
                      </Form.Item>
                      <Form.Item rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]} name='total_fat' label="Total Fat">
                        <Input
                          onChange={(e) =>
                            setNutritionFacts({
                              ...NutritionFacts,
                              total_fat: e.target.value,
                            })
                          }
                          type="number"
                        />
                      </Form.Item>
                      <Form.Item rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]} name='saturated_fat'  label="Saturated">
                        <Input
                          onChange={(e) =>
                            setNutritionFacts({
                              ...NutritionFacts,
                              saturated: e.target.value,
                            })
                          }
                          type="number"
                        />
                      </Form.Item>
                      <Form.Item rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]} name='trans_fat'  label="Trans">
                        <Input
                          onChange={(e) =>
                            setNutritionFacts({
                              ...NutritionFacts,
                              trans_fat: e.target.value,
                            })
                          }
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item label="Switch" valuePropName="checked">
                    <Switch />
                  </Form.Item>
               
                <Button htmlType="submit" type="primary" style={{marginTop:'10px'}} icon={<PlusOutlined />}> Add Nutrition</Button>
                </Form>
              </Col>
            </Row>
          </Box>
        </Col>
        <Col span={7}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              borderRadius: "8px",
              flex: 1,
              margin: isMobileView ? "25px 10px" : "10px 10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Nutrition props={NutritionFacts} />
          </Box>
        </Col>
      </Row>
    </>
  );
};

export default Createlabel;
