import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../api/api";
import { Box, Grid, Typography } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { QRCode } from "react-qrcode-logo";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import {
  Col,
  Divider,
  Row,
  Button,
  Form,
  Input,
  Radio,
  Switch,
  Upload,
  Modal,
  Table,
  Popover,
  Space,
} from "antd";
import { IoCopyOutline } from "react-icons/io5";

import useScreenSize from "../hooks/useScreenSize";
const client = new S3Client({
  region: "us-east-1",
  endpoint: "http://play.min.io:9000",
  credentials: {
    accessKeyId: "NxGyiHKb1zHOaWxhUqfa",
    secretAccessKey: "iP5thhnuZMpLYX9Umzrl3WmGuVvEagMvZuaMSO1u",
  },
});
const { Column } = Table;

const headers = {
  "x-access-key":
    "80761972331f7635c1e37affb6000e04e8a810c9e740e2a998f22f65ed685f69",
  "x-secret-key":
    "0fea4d49d51e4eedd86a0140f917ecd0cac9570e1451607d9ce1deb6e75e0d9319520bf2558b5780597c281b0caaacaf",
};
const emptyData = [
  {
    qr_id: "",
    status: "",
    url: "",
  },
];

const Qrcode = () => {
  const [isMobileView] = useScreenSize();
  const navigate = useNavigate();
  const [qrCodeData, setQrCodeData] = useState(emptyData);
  let { certid } = useParams();
  const [openUrl, setOpenUrl] = useState(false);
  const hide = () => {
    setOpenUrl(false);
  };
  
  const handleOpenChange = (newOpen) => setOpenUrl(newOpen);

  useEffect(() => {
    axiosInstance
      .get(`/api/qrcode/read/all`,{
        headers: {
          "Authorization":
          `Bearer ${localStorage.getItem("accessToken")}`,

      }})
      .then((res) => {
        let data = res.data.map((item) => {
          return {
            qr_id: item.qr_code,
            status: item.published ? "Published" : "Unpublished",
            url: item.url,
            name: item.name,
          };
        });
        setQrCodeData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  , []);

  return (
    <>
      <Toaster position="bottom-right" reverseOrder={false} />
      {/* generate qr code and attach with label */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: isMobileView ? "20px !important" : "40px !important",
          background: "#fff",
          gap: "30px",
          borderRadius: "8px",
          flex: 1,
          margin: isMobileView ? "25px 10px" : "10px 10px",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
          Certificate for {certid}
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Table
            scroll={{
              x: 1200,
            }}
            style={{ width: "100%" ,
              margin: "10px 10px",
              
            }}
            bordered
            dataSource={qrCodeData}
          >
            <Column width={200} title="Qr Id" dataIndex="qr_id" key="qr_id" />
            <Column width={200} title="Qr Name" dataIndex="name" key="name" />
            <Column width={200} title="Status" dataIndex="status" key="status" />
            <Column
              title="Url"
              dataIndex="url"
              key="url"
              render={(_, record) =>
                renderCopyIcon(
                  record.url,
                  hide,
                  openUrl,
                  handleOpenChange
                )
              }
            />
            <Column
              title="Action"
              key="action"
              width={200}
              render={(_, record) => (
                <Space size="middle">
                  <a>Delete</a>
                </Space>
              )}
            />
          </Table>
          
          <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
          <Button
            style={{ position: "middle", maxWidth: "200px" }}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {navigate("/create-qrcode")}}
          >
            {" "}
            Add Qr Code
          </Button>
        </Typography>
        </Grid>
      </Box>
      
    </>
  );
};

const renderCopyIcon = (text, hide, open, handleOpenChange) => {
  return (
    <Space size="middle">
      {text}
      <Popover
        content={<a onClick={hide}>Close</a>}
        title="Copied"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <IoCopyOutline
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigator.clipboard.writeText(text);
          }}
        />
      </Popover>
    </Space>
  );
};

export default Qrcode;
