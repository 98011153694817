import React,{useState} from 'react';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
const { Header, Content, Footer, Sider } = Layout;
const items = [
  {
    key: '1',
    label: 'nav 1',
    icon: <UserOutlined />,
    onClick: () => console.log('nav 1'),
  },
  {
    key: '2',
    label: 'nav 2',
    icon: <VideoCameraOutlined />,
  },
  {
    key: '3',
    label: 'nav 3',
    icon: <UploadOutlined />,
  },
  {
    key: '4',
    label: 'nav 4',
    icon: <UserOutlined />,
  },
  {
    key : '5',
    label: 'nav 5',
    icon: <UserOutlined />,
  }
];


const Home = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  return (
    <>
    </>
  );
};
export default Home;