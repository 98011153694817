import './App.css';
import { Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout1 from './components/Layout';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Profile from './components/Profile';
import Labels from './components/Labels';
import Createlabel from './components/Createlabel';
import Error from './templates/Error';
import Createsllcert from './components/Createsllcert';
import Forgotpassword from './components/Forgotpassword';
import Resetpassword from './components/Resetpassword';
import Varifyemail from './components/Varifyemail';
import Certdetails from './components/Certdetails';
import Qrcode from './components/Qrcode';
import Createqrcode from './components/Createqrcode';
function App() {
  return (
    
    <Routes>
        <Route path="/" element={<Layout1 />} >
        <Route index element={<Home/>} />
        
        <Route path={"/profile"} element={<Profile/>} />
        <Route path={"/ssl-cert"} element={<Createsllcert/>} />
        <Route path={"/label"} element={<Labels/>} />
        <Route path={"/create-label"} element={<Createlabel/>} />
        <Route path={"/cert-details/:certid"} element={<Certdetails/> } />
        <Route path={"/qrcode"} element={<Qrcode/> } />
        <Route path={"/create-qrcode"} element={<Createqrcode/> } />
        <Route path={"/forgot-password"} element={<Forgotpassword/> } />
        <Route path={"/reset-password/:token"} element={<Resetpassword/> } />
        <Route path={"/varify-email/:token"} element={<Varifyemail />} />
        <Route path={"*"} element={<Error/>} />
        </Route>
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
      </Routes>
  );
}

export default App;
