import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../api/api";
import { Box, Grid, Typography,  } from "@mui/material";
import {   Space, Table, Popover } from "antd";
import useScreenSize from "../hooks/useScreenSize";
import { IoCopyOutline } from "react-icons/io5";

const { Column } = Table;
const emptyData = [
    {
        "cert_id": "",
        "domain_name": "",
        "status": "",
        "cname_name": "",
        "cname_value": ""
    }
]
const Certdetails = () => {
    const [certdata, setCertdata] = useState(emptyData);
    let { certid } = useParams();
    const [openCnameValue, setOpenCnamevalue] = useState(false);
    const [openCname, setOpenCname] = useState(false);
    const hide = () => {
        setOpenCname(false);
        setOpenCnamevalue(false);
    };
    const handleOpenChange = (newOpen) => setOpenCname(newOpen);
    const handleOpenChangeValue = (newOpen) => setOpenCnamevalue(newOpen);
    const [isMobileView] = useScreenSize();
    const getCertDetails = async () => {
        try {
            const res = await axiosInstance.get(`/api/ssl-cert/read/${certid}`,{
                headers: {
                    "Authorization":
                        `Bearer ${localStorage.getItem("accessToken")}`,
                }
            });
            if (res.status === 200) {
                console.log(res.data.data);

                setCertdata([{
                    "cert_id": res.data.data.cert_id,
                    "domain_name": res.data.data.domain_name,
                    "status": res.data.data.status,
                    "cname_name": res.data.data.cname_name,
                    "cname_value": res.data.data.cname_value
                }]);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        console.log(certid);
        getCertDetails();
    }, []);
    return (
        <>
            <Toaster position='bottom-right' reverseOrder={false} />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: isMobileView ? "20px !important" : "40px !important",
                    background: "#fff",
                    gap: "30px",
                    borderRadius: "8px",
                    flex: 1,
                    margin: isMobileView ? "25px 10px" : "10px 10px",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                    alignContent: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
                    Certificate for {certid}
                </Typography>
                <Grid
                    container
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        alignContent: "center",
                        justifyContent: "center",
                    }}
                >
                    <Table scroll={{
                        x: 1500,
                    }} dataSource={certdata}>
                        <Column title="Cert Id" dataIndex="cert_id" key="cert_id"  />
                        <Column title="Domain Name" dataIndex="domain_name" key="domain_name"  />
                        <Column title="Status" dataIndex="status" key="status" />
                        <Column title="Cname Name" dataIndex="cname_name" key="cname_name" render={(_, record) => ( renderCopyIcon(record.cname_name,hide,openCname,handleOpenChange) )} />
                        <Column title="Cname Value" dataIndex="cname_value" key="cname_value"render={(_, record) => ( renderCopyIcon(record.cname_value,hide,openCnameValue,handleOpenChangeValue) )} />

                        <Column
                            title="Action"
                            key="action"
                            render={(_, record) => (
                                <Space size="middle">
                                    <a>Delete</a>
                                </Space>
                            )}
                        />
                    </Table>
                </Grid>
            </Box>
        </>
    );
}
const renderCopyIcon = (text,hide,open,handleOpenChange) => {
    return (
        <Space size="middle">
                                    {text}
        <Popover
            content={<a onClick={hide}>Close</a>}
            title="Copied"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
        >
            <IoCopyOutline style={{ cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(text) }} />
        </Popover>
    </Space>
    );
};
export default Certdetails;