import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast, { toast, Toaster } from "react-hot-toast";
import axiosInstance from "../api/api";
import { Box, Grid, Typography } from "@mui/material";
import { PlusOutlined ,CheckOutlined, CloseOutlined} from "@ant-design/icons";
import { QRCode } from "react-qrcode-logo";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import downloadjs from "downloadjs";
import {S3Client,PutObjectCommand,GetObjectCommand,} from "@aws-sdk/client-s3";
import { Col, DatePicker, Row, Button, Form, Input, Radio, Switch, Upload, Table, Popover, Space,} from "antd";

import useScreenSize from "../hooks/useScreenSize";
const client = new S3Client({
  region: "us-east-1",
  endpoint: "http://play.min.io:9000",
  credentials: {
    accessKeyId: "NxGyiHKb1zHOaWxhUqfa",
    secretAccessKey: "iP5thhnuZMpLYX9Umzrl3WmGuVvEagMvZuaMSO1u",
  },
});
const { Column } = Table;

const headers = {
  "x-access-key":
    "80761972331f7635c1e37affb6000e04e8a810c9e740e2a998f22f65ed685f69",
  "x-secret-key":
    "0fea4d49d51e4eedd86a0140f917ecd0cac9570e1451607d9ce1deb6e75e0d9319520bf2558b5780597c281b0caaacaf",
};

const Createqrcode = () => {
  const navigate = useNavigate();
  const [timestamp, setTimestamp] = useState(0);
  const [isMobileView] = useScreenSize();
  const pickDate = (date, dateString) => {
    let dateObject = new Date(dateString);
    let timestamp = dateObject.getTime();
    console.log("timestamp", timestamp / 1000);
    setTimestamp(timestamp / 1000);
  };

  const [QrCode, setQrCode] = useState({
    url: "https://digilabel.in",
    name: "digilabel",
    tenant_uuid: "6b997fb2-e950-4380-9f86-7642c6386418",
  });

  const onFinish = (values) => {
    console.log("Success:", values);
    htmlToImage
      .toPng(document.getElementById("react-qrcode-logo"))
      .then(async function (dataUrl) {
        const resp = await axiosInstance.post(
          "/api/qrcode/create",
          {
            data: dataUrl,
            expiry: timestamp,
            type: 1,
            published: values.published,
            name: values.name,
            tenant_uuid: values.tenant_uuid,
          },
          {
            
              headers: {
                "Authorization":
                `Bearer ${localStorage.getItem("accessToken")}`,
      
            }
          }
        );
        console.log(resp);
        toast.success("QrCode created successfully");
        navigate("/qrcode");
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
        toast.error(`Error generating QR code : ${error.response.data.message}`, { duration: 4000 });
      });
  };

  const downloadQr = async (e) => {
    e.preventDefault();
    htmlToImage
      .toPng(document.getElementById("react-qrcode-logo"))
      .then(async function (dataUrl) {
        const blob = await fetch(dataUrl).then((r) => r.blob());
        const formData = new FormData();
        formData.append("file", blob);
        downloadjs(blob, "qr.png");
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  };

  

  return (
    <>
      {/* generate qr code and attach with label */}
      <Row>
        <Col span={17}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: isMobileView ? "20px !important" : "40px !important",
              background: "#fff",
              borderRadius: "8px",
              flex: 1,
              margin: isMobileView ? "25px 10px" : "10px 10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Row>
              <Col flex={10}>
                <Form
                  initialValues={{
                    url: QrCode.url,
                    name: QrCode.name,
                    tenant_uuid: QrCode.tenant_uuid,
                    published: false,
                  }}
                  onFinish={onFinish}
                  name="basic"
                >
                  <Form.Item name="url"
                    rules={[
                      {
                        required: true,
                        message: "Please Url!",
                      },
                    ]}
                    label="Url"
                  >
                    <Input
                      value={QrCode.url}
                      placeholder="https://digilabel.in"
                      onChange={(e) =>
                        setQrCode({
                          ...QrCode,
                          url: e.target.value,
                        })
                      }
                      type="url"
                    />
                  </Form.Item>
                  <Form.Item name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    label="Name"
                  >
                    <Input
                      placeholder="Name"
                      onChange={(e) =>
                        setQrCode({
                          ...QrCode,
                          name: e.target.value,
                        })
                      }
                      type="string"
                    />
                  </Form.Item>
                  <Form.Item name="tenant_uuid"
                    rules={[
                      {
                        required: true,
                        message: "Please input tenant_uuid",
                      },
                    ]}
                    label="tenant_uuid"
                  >
                    <Input
                      value={QrCode.tenant_uuid}
                      placeholder="tenant_uuid"
                      onChange={(e) =>
                        setQrCode({
                          ...QrCode,
                          name: e.target.value,
                        })
                      }
                      type="string"
                    />
                  </Form.Item>
                  <Form.Item name="expiry"
                    rules={[
                      {
                        required: true,
                        message: "Please input published",
                      },
                    ]}
                    label="expiry"
                  >
                    <DatePicker onChange={pickDate} />
                  </Form.Item>
                  <Form.Item name="published" 
                    label="Published" 
                    defaultChecked={false}>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={false}
                  />
                  </Form.Item>
                  
                  {/* upload logo for qr form iteam */}
                  <Form.Item
                    name="logo"
                    label="Logo"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                      console.log("Upload event:", e);
                      return e.fileList;
                    }}
                  >
                    {/* console log file base 64 data from upload */}
                    <Upload
                      name="logo"
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture"
                    >

                      <Button icon={<PlusOutlined />}>Upload Logo</Button>
                    </Upload>
                  </Form.Item>

                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{ marginTop: "10px" }}
                    icon={<PlusOutlined />}
                  >
                    {" "}
                    Save QrCode
                  </Button>
                </Form>
              </Col>
            </Row>
          </Box>
        </Col>
        <Col span={7}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              borderRadius: "8px",
              flex: 1,
              padding: isMobileView ? "20px !important" : "20px !important",
              margin: isMobileView ? "25px 10px" : "10px 10px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            {/* QR code section with temporary qr code */}
            <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
              QR Code
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: isMobileView ? "25px 10px" : "25px 10px",
              }}
            >
              <QRCode
                style={{ borderRadius: "8px", border: "1px solid black" }}
                value={QrCode.url}
                size={200}
                logoImage="https://www.npmjs.com/static/images/logo.svg"
                logoWidth={50}
                logoHeight={50}
                qrStyle={QrCode.style}
              />
            </Box>
            <Button
              type="primary"
              style={{ margin: "10px" }}
              onClick={(e) => {
                downloadQr(e);
              }}
            >
              Download
            </Button>
          </Box>
        </Col>
      </Row>
    </>
  );
};

export default Createqrcode;
